import { Text } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'
import { NextPage } from 'next'
import React from 'react'
import s from './404Page.module.scss'
import PageBackground from 'compositions/PageBackground/PageBackground'
import cx from 'classnames'
import dayjs from 'dayjs'
import ScrambledText from 'components/dataDisplay/ScrambledText/ScrambledText'
import TextRepetition from 'components/dataDisplay/TextRepetition/TextRepetition'
import { useDevice } from 'device'


const Page404: NextPage = () => {
  const { isMobile } = useDevice()

  return (
    <PageBackground>
      <WidthContainer>
        <div className={cx('flex flex-col justify-center text-center', s.page)}>
          <TextRepetition style="h1" message="404" />
          <Text className="mt-18" style="h5" html message={`Hey! I'm ${isMobile ? '<br />' : ''} sleeping here!`} />
          <Text
            className="mt-32"
            style="sub"
            message="It looks like the page you enter doesn't exist. Maybe you can find what you're looking for below."
            color="gray-60"
          />
        </div>
        <div className={s.bottom}>
          <ScrambledText className="mt-18" style="glitch18" message={`[ ${dayjs().format('MMMM DD')} ]`} />
          <div className="flex flex-col items-end">
            <ScrambledText className="mt-18" style="glitch18" message={'7.babylon.art'} />
            <ScrambledText className="mt-18" style="glitch18" message={'st$tus: re%u\ld/ng'} />
          </div>
        </div>
      </WidthContainer>
    </PageBackground>
  )
}

export default Page404
